import { DeviceDto } from './api';
import { Configuration } from './configuration';
import { isServerSide } from '@/helpers/IsServerSide';
import { CookiesHelper } from '@/helpers/CookiesHelper';
import { Constants } from '@/helpers/Constants';
import * as uuid from 'uuid';

const { version } = require('../../package.json');
const uuidv4 = uuid.v4;

export const API_URL = isServerSide()
    ? process.env.NEXT_PUBLIC_API_DIRECT_URL || process.env.NEXT_PUBLIC_API_URL as string
    : process.env.NEXT_PUBLIC_API_URL as string;

export const apiConfiguration: Configuration = new Configuration({
    basePath: API_URL,
    includeCredentials: true,
});

const getDeviceId = () => {
    if (!CookiesHelper.hasCookie(Constants.Track.KEY_DEVICE_COOKIE_NAME)) {
        const val = uuidv4()
        CookiesHelper.setCookie(Constants.Track.KEY_DEVICE_COOKIE_NAME, val);
        return val
    }
    return CookiesHelper.getCookie(Constants.Track.KEY_DEVICE_COOKIE_NAME) || uuidv4();
}

const getTimezone = () => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        
    }
    return undefined
}

export const getDevice = async () => {
    return {
        device_uuid: getDeviceId(),
        language: navigator.language,
        timezone: getTimezone(),
        app_type: 'web_app',
        app_version: version,
        build_version: 1,
        debug: false,
        // ToDo: Add other optional properties
    } as DeviceDto;
};
