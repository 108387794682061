import { Constants } from './Constants';
import { isServerSide } from './IsServerSide';

export abstract class CookiesHelper {
    private constructor() {
        /* noop */
    }

    static extractTokenExpirationDate = (cookieName: string): Date | null => {
        const cookieRegex = new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`
        );
        const cookieMatch = document.cookie.match(cookieRegex);

        if (cookieMatch && cookieMatch[1]) {
            const token = cookieMatch[1];

            try {
                const decodedToken = JSON.parse(atob(token.split('.')[1]));
                const expirationTimestamp = decodedToken.exp * 1000; // Convert to milliseconds
                return new Date(expirationTimestamp);
            // eslint-disable-next-line no-empty
            } catch (error) {}
        }

        return null;
    };

    static getCookie(cookieName: string) {
        if (isServerSide()) {
            return false;
        }
        const cookieRegex = new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`
        );
        const cookieMatch = document.cookie.match(cookieRegex);
        return cookieMatch ? cookieMatch[1] : undefined;
    }

    static hasCookie(cookieName: string) {
        if (isServerSide()) {
            return false;
        }
        const cookieRegex = new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`
        );
        const cookieMatch = document.cookie.match(cookieRegex);
        return cookieMatch && !!cookieMatch[1];
    }

    static unsetCookie(name: string) {
        if (isServerSide()) {
            return false;
        }
        const domain = '.therealest.com';
        const path = '/';
        document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`;
    }

    static setCookie(name: string, value: string) {
        if (isServerSide()) {
            return false;
        }
        try {
            const domain = '.therealest.com';
            const path = '/';
            const date = new Date();
            date.setTime(date.getTime() + (365 * 5 * 24 * 60 * 60 * 1000));
            document.cookie = `${name}=${value}; expires=${date.toUTCString()}; domain=${domain}; path=${path}`;
        } catch (error) {
            /* noop */
        }
    }

    static hasRefreshToken() {
        if (isServerSide()) {
            return false;
        }
        return !!localStorage.getItem(
            Constants.Auth.KEY_REFRESH_TOKEN
        );
    }
}
