import { AnalyticsBrowser } from '@segment/analytics-next';

export const analytics = AnalyticsBrowser.load(
    {
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
        cdnURL: 'https://tc.therealest.com'
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: 't.therealest.com/v1',
                protocol: 'https'
            }
        }
    }
);

export const trackClick = (name?: string, onClick?: (e?: React.MouseEvent<HTMLElement>) => void) => (e?: React.MouseEvent<HTMLElement>) => {
    analytics.track('Click', { name: name || e?.currentTarget.textContent || '' });
    onClick?.();
};
