'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { Bars } from 'react-loader-spinner';
import React, { useEffect, useRef, useState } from 'react';
import { UrlObject } from 'url';
import { analytics } from '@/helpers/Analytics';

export type IButtonProps = {
    size?: 'sm' | 'md' | 'lg' | 'xl';
    children: string | Element | any;
    onClick?: (e?: React.MouseEvent) => void;
    href?: string | UrlObject;
    type?: 'submit' | 'reset' | 'button' | undefined;
    fullWidth?: boolean;
    disabled?: boolean;
    loading?: boolean;
    light?: boolean;
    lightGray?: boolean;
    className?: string;
    linkTarget?: string;
};

const Button = (props: IButtonProps) => {
    const {
        size = 'sm',
        children,
        onClick,
        href,
        fullWidth,
        disabled = false,
        light = false,
        lightGray = false,
        className = null,
        loading = false,
        type = 'button',
        linkTarget
    } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [buttonSize, setSize] = useState<{ width: number | string, height: number } | undefined>();

    useEffect(() => {
        if (buttonRef.current) {
            const { width, height } = buttonRef.current.getBoundingClientRect();
            setSize({ width: fullWidth ? '100%' : width, height });
        } else {
            setSize(undefined);
        }
    }, [fullWidth]);

    const loadingStyle = loading ? buttonSize || {} : {};

    const onClickWithAnalytics = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e?.currentTarget) {
            let text = '';
            if (Array.isArray(children)) {
                text = children.filter((a) => typeof a === 'string').join('');
            } else if (typeof children === 'string') {
                text = children;
            }
            analytics.track('Click', { name: text, href });
        }

        onClick?.(e);
    };
    const button = (
        <button
            ref={buttonRef}
            type={type}
            disabled={disabled || loading}
            onClick={onClickWithAnalytics}
            style={{ ...loadingStyle }}
            className={clsx(
                'group text-[16px] max-h-[48px] min-w-[140px] shadow-btn-default capitalize font-secondary rounded-full cursor-pointer relative overflow-hidden flex justify-center items-center pt-[10px] pb-[12px] px-[16px] font-[500]  disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed focus:outline-none focus:ring focus:ring-blue14 focus:ring-[4px]',
                light
                    ? 'bg-white border-[1px] border-grey13 hover:enabled:bg-grey16 text-blue15 active:bg-grey14 disabled:text-grey15'
                    : 'bg-black text-white  hover:bg-blue12 disabled:bg-grey14 disabled:text-grey15 active:bg-blue13 active:text-grey15',
                lightGray && '!bg-grey16 hover:enabled:!bg-white',
                fullWidth ? 'w-full' : '',
                className,
                loading ? '!flex' : '',
                size === 'sm' && '!text-[14px] !px-[12px] !py-[12px] min-w-[unset] max-h-[34px]'
            )}
        >
            {loading ? (
                <Bars
                    height="20"
                    width="20"
                    color="#ccc"
                    wrapperStyle={{}}
                    wrapperClass="wrapper-class"
                    visible
                />
            ) : (
                children
            )}
        </button>
    );

    return href ? <Link prefetch={false} target={linkTarget} className="block no-hover" href={href}>{button}</Link> : button;
};

export default Button;
